import AnimatedPage from "../components/AnimatedPage";
import PropertyPageContent from "../components/PropertyPageContent";
import React from "react";
import { graphql } from "gatsby";

const PropertyTemplate = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const { title, description, featured, blocks } = frontmatter;

  return (
    <AnimatedPage>
      <PropertyPageContent
        title={title}
        description={description}
        featured={featured}
        blocks={blocks}
      />
    </AnimatedPage>
  );
};

export const query = graphql`
  query PropertyBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        title
        description
        featured {
          childImageSharp {
            gatsbyImageData(
              width: 1024
              layout: FULL_WIDTH
              formats: [AUTO, WEBP, AVIF]
              quality: 80
              placeholder: BLURRED
            )
          }
        }
        blocks {
          type
          text
          title
          galleryImages {
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 1024
                  layout: FULL_WIDTH
                  formats: [JPG]
                  quality: 80
                  aspectRatio: 1.5
                  placeholder: BLURRED
                )
              }
            }
            caption
          }
          entries {
            contact
            handover
            additional
            price
            floor
            area
            kind
            address
            rooms
            url
            factsheet {
              publicURL
            }
          }
        }
      }
    }
  }
`;

export default PropertyTemplate;
