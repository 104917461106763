import { GatsbyImage, getImage } from "gatsby-plugin-image";

import BlocksSection from "../components/BlocksSection";
import React from "react";

const PropertyPageContent = ({ title, description, featured, blocks }) => {
  const gatsbyImageData = getImage(featured);

  return (
    <>
      <div className="jumbotron">
        {typeof featured !== "string" ? (
          <GatsbyImage
            image={gatsbyImageData}
            className="jumbotron-image"
            alt=""
            imgStyle={{ objectPosition: "center center" }}
          />
        ) : (
          <div className="jumbotron-image">
            <img
              src={featured}
              style={{
                objectPosition: "center center",
                objectFit: "cover",
                width: "100%",
                height: "100%",
              }}
              alt=""
            />
          </div>
        )}
        <div className="jumbotron-content-container">
          <div className="container grid-960 arrow">
            <div className="columns">
              <div className="column col-6 col-md-12">
                <div className="jumbotron-content">
                  <h1 className="font-hill-bold text-uppercase mb-10">
                    {title}
                  </h1>
                  <p>{description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BlocksSection blocks={blocks} />
    </>
  );
};

export default PropertyPageContent;
